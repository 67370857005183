import React, { useState } from 'react';
import './App.css'; // Ensure you have this CSS file for styling
import logo from './logo.png'; // Replace with the correct path to your logo

function App() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [sex, setSex] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const isValidName = (name) => /^[a-zA-Z\s]+$/.test(name);
  const isValidDateOfBirth = (dob) => {
    const selectedDate = new Date(dob);
    const today = new Date();
    return selectedDate <= today;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidName(firstName) || !isValidName(lastName)) {
      alert("Name fields should only contain letters.");
      return;
    }

    if (!isValidDateOfBirth(dob)) {
      alert("Date of Birth cannot be in the future.");
      return;
    }

    try {
      const response = await fetch('/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          dob,
          sex,
          phoneNumber,
          email,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      } else {
        const result = await response.json();
        setSubmitted(true);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      alert(
        'An unexpected error occurred. Please check the network connection or contact support.'
      );
    }
  };

  const handleReset = () => {
    setFirstName('');
    setLastName('');
    setDob('');
    setSex('');
    setPhoneNumber('');
    setEmail('');
    setSubmitted(false);
  };

  const getPronoun = (sex) => {
    switch (sex) {
      case 'Male':
        return 'he';
      case 'Female':
        return 'she';
      default:
        return 'they';
    }
  };

  return (
    <div className="form-container">
      <img src={logo} alt="JFM-CCS Logo" className="logo" />
      <h1>JFM-CCS Registrar</h1>
      {submitted ? (
        <>
          <p className="submission-message">
            Thank you! Your submission has been received by Jenks Family Medicine. We will review it and email you the necessary intake forms within one business day.
            <br />
            <br />
            <strong>Need to expedite the process?</strong>
            <br />
            Feel free to call us at{' '}
            <a href="tel:4233735457">423-373-5457</a> during business hours, and a team member will send the forms right away.
            <br />
            <br />
            Please check your email for the intake forms for{' '}
            <strong>
              {firstName} {lastName}
            </strong>
            . Once the forms are completed, {getPronoun(sex)} will be ready to be seen by one of our providers at CCS.
          </p>
          <button onClick={handleReset} className="submit-button">
            Register Another Person
          </button>
        </>
      ) : (
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label>Patient's First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Patient's Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Patient's Date of Birth:</label>
            <input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Patient's Sex:</label>
            <select
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              required
            >
              <option value="">Select Sex</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label>Patient/Parent/Guardian Phone Number:</label>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Patient/Parent/Guardian Email Address:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Register
          </button>
        </form>
      )}
    </div>
  );
}

export default App;
